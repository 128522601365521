
































































































































































































import { namespace } from 'vuex-class';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import { validationMixin } from 'vuelidate';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import Dealer from '@/models/Dealer.model';
import { DealerStoreActions } from '@/store/dealer.store';
import { StoresStoreActions, StoresStoreGetters } from '@/store/stores.store';
import Store from '@/models/Store.model';
import { DealerRoles } from '@/enum/DealerRoles.enum';
import BaseMixin from '@/misc/BaseMixin.mixins';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';
import EventBus from '@/misc/EventBus';

const DealerStore = namespace('dealer');
const StoresStore = namespace('stores');

@Component({
  components: {
    StorePropertiesComponent: () => import(
      /* webpackChunkName: "StorePropertiesComponent" */
      '@/components/StoreProperties.component.vue'
    )
  },
  mixins: [validationMixin],
  validations: {
    dealerCopy: {
      email: { email, required },
      // @ts-ignore
      store: { required: requiredIf(function (model) { return !this.isRegisterPage; }) },
      role: { required },
    },
  }
})
export default class EditDealerComponent extends mixins(ErrorMessageHandlerMixin, BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ default: () => new Dealer() })
  public dealer!: Dealer;

  @Prop({ default: true })
  public showStoreTab!: boolean;

  // Is component loaded in modal dialog?
  @Prop({ default: true })
  public isModal!: boolean;

  @Prop({ default: false })
  public isRegisterPage!: boolean;

  @DealerStore.Action(DealerStoreActions.CREATE)
  private createDealerAction!: (dealer: Dealer) => Promise<Dealer>;

  @DealerStore.Action(DealerStoreActions.UPDATE)
  private updateDealerAction!: (dealer: Dealer) => Promise<Dealer>;

  @DealerStore.Action(DealerStoreActions.REGISTER_DEALER)
  private registerDealerAction!: (dealer: Dealer) => Promise<Dealer>;

  @StoresStore.Action(StoresStoreActions.GET_ALL)
  private getAllStoresAction!: () => Promise<Store>;

  @StoresStore.Action(StoresStoreActions.GET_BY_ID)
  private getStoreByIdAction!: (id: string) => Promise<Store>;

  @StoresStore.Getter(StoresStoreGetters.STORES)
  private allStores!: Store[];

  private dealerCopy: Dealer = new Dealer();
  private isValid = true;
  private isLoading = false;
  private selectedStep: number = 1;

  private showStoreCreate: boolean = this.isRegisterPage;

  private steps = [
    { title: 'DEALER.DIALOG.MASTER_DATA', validations: ['dealerCopy.email', 'dealerCopy.role'], complete: false, valid: true, hide: false },
    { title: 'DEALER.DIALOG.STORE', validations: ['dealerCopy.store'], complete: false, valid: true, hide: !this.showStoreTab },
  ];

  // Error message for duplicate email:
  private duplicateEmailMessage: string | null = null;

  mounted() {
    // Add event listener for saving data
    EventBus.$on('SAVE_DEALER', this.saveData);
    // Add event listener for setting created store:
    EventBus.$on('SET_DEALER_STORE', this.setStore);
  }

  async created() {
    // Load stores for autocomplete-component (only if component is visible):
    if (this.showStoreTab && !this.isRegisterPage) {
      this.loadStores();
    }
  }

  @Watch('dealer', { immediate: true })
  public async onDealerChanged() {
    if (this.dealer) {
      this.dealerCopy = this.dealer.copy() as Dealer;
      // Convert store id to object:
      if (typeof this.dealerCopy.store === "string") {
        try {
          this.isLoading = true;
          const store = await this.getStoreByIdAction(this.dealerCopy.store);
          this.dealerCopy.store = store;
        } catch (e) {
          this.handleAxiosError(e);
        } finally {
          this.isLoading = false;
        }
      }
      // Trigger validation check when editing:
      if (this.dealer.id) {
        this.checkAllValidations();
      }
    }
    this.isValid = !this.$v!.$invalid;
  }

  /**
   * Prevents linear and non-linear change of step if validation failed
   */
  @Watch('selectedStep', { immediate: true })
  public onStepChange(newVal: number, oldVal: number) {
    this.$nextTick(async () => {
      if (oldVal) {
        const isValid = await this.checkValidations(this.steps[oldVal - 1]);
        this.steps[oldVal - 1].complete = isValid;
        // If previous step is lower (before) current step
        // (preventing to move forward if validation fails) 
        if (oldVal < newVal) {
          this.nextStep(oldVal, newVal);
        }
      }
    });
  }

  /**
   * Moves from {currentStep} to {nextStep} in stepper if validation for {currentStep} succeeds.
   * @param currentStep Currently selected step.
   * @param nextStep Step to be switched to.
   */
  private async nextStep(currentStep: number = this.selectedStep, nextStep: number = this.selectedStep + 1) {
    if (!this.duplicateEmailMessage) {
      const step = this.steps[currentStep - 1];
      const isValid = await this.checkValidations(step);
      if (isValid) {
        this.selectedStep = nextStep;
      } else {
        this.selectedStep = currentStep;
      }
    } else {
      this.selectedStep = currentStep;
    }
  }

  /**
   * Returns representations of available roles.
   * Only store admins can name other store admins.
   * Only admins can name other admins.
   */
  get availableRoleRepresentations() {
    const allRoles = this.roleRepresentations;
    const dealerRole = allRoles[DealerRoles.DEALER];
    const storeAdminRole = allRoles[DealerRoles.STORE_ADMIN];
    const adminRole = allRoles[DealerRoles.ADMIN];

    const availableRoles = [dealerRole];
    if (this.currentUser.isStoreAdmin || this.currentUser.isAdmin) {
      availableRoles.push(storeAdminRole);
    }
    if (this.currentUser.isAdmin) {
      availableRoles.push(adminRole);
    }
    return availableRoles;
  } 

  private async loadStores() {
    try {
      this.isLoading = true;
      await this.getAllStoresAction();
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private duplicateEmailError() {
    this.selectedStep = 1; // Move to step that contains email input
    this.duplicateEmailMessage = this.$t('GENERAL.VALIDATION_MESSAGES.DUPLICATE_EMAIL').toString();
    this.steps[0].valid = false;
    this.isValid = false;
  }

  private resetDuplicateEmailError() {
    this.duplicateEmailMessage = null;
    this.steps[0].valid = true;
    this.isValid = true;
  }

  /**
   * Checks all validations for fields in given step.
   * @returns True if all validations are valid, else false.
   */
  private async checkValidations(step: any): Promise<boolean> {
    let isValid = true;
    for (let s of step.validations) {
      let valid = await this.checkForm(s);
      if (!valid) isValid = false;
    }
    step.valid = isValid;
    return isValid;
  }

  /**
   * Checks all validations of all steps.
   * Sets value for { this.isValid }.
   */
  private async checkAllValidations() {
    this.isValid = true;
    for (let step of this.steps) {
      if (!await this.checkValidations(step)) {
        this.isValid = false;
      }
    }
  }

  private async saveData() {
    await this.checkAllValidations();

    if (this.isValid && !this.isLoading) {
      try {
        this.isLoading = true;
        if (this.isRegisterPage) {
          await this.registerDealerAction(this.dealerCopy);
        } else {
          if (!this.dealer.id) {
            await this.createDealerAction(this.dealerCopy);
          } else {
            await this.updateDealerAction(this.dealerCopy);
          }
        }
        this.dismiss(true);
      } catch (e) {
        this.handleAxiosError(e, () => {
          switch (e.status) {
            case 409: // Duplicate e-mail
              this.duplicateEmailError();
              EventBus.$emit('DUPLICATE_DEALER_EMAIL');
              break;
            default:
              this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.DEALER_ERROR');
          }
        });        
      } finally {
        this.isLoading = false;
      }
    }
  }

  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }

  private async checkForm(type: string): Promise<boolean> {
    const inputValid = await this.triggerValidation(type);
    this.isValid = !this.$v!.$invalid;
    const affectedStep = this.steps.find(step => step.validations.indexOf(type) != -1);
    affectedStep!.valid = inputValid;
    return inputValid;
  }

  public setStore(store: Store) {
    // Set store for current dealer:
    this.dealerCopy.store = store;
    this.showStoreCreate = false;
  }

  private dealerRegistrationFinished() {
    this.checkAllValidations();
    if (this.isValid) {
      EventBus.$emit('DEALER_REGISTRATION_FINISHED');
    }
  }
}
